import { iFrameParams } from '@mgdis/eu-domain';

class ContributionsInformationListComponent {
  constructor(
    $sce,
    $httpParamSerializer,
    $state,
    $timeout,
    configuration,
    jwtSessionService,
    IFrameCommunicationManager
  ) {
    this.$sce = $sce;
    this.$httpParamSerializer = $httpParamSerializer;
    this.$state = $state;
    this.$timeout = $timeout;
    this.configuration = configuration;
    this.jwtSessionService = jwtSessionService;
    this.iFrameCommunicationManager = new IFrameCommunicationManager('#contributionsInformationList');
  }

  $onInit() {
    const iframeQueryParams = this.$httpParamSerializer({
      jwtKey: this.jwtSessionService.getJwtKey(),
      tiersKey: iFrameParams.TIERS_KEY,
      demandeFinancementReference: this.demandeFinancementReference,
    });

    const templateIframeSrc = `${this.configuration.ux}<%= tenantId %>/contributions-information-usager-list-view?<%= queryParams %>`;
    const compiledIframeSrc = _.template(templateIframeSrc);
    const iframeSrc = compiledIframeSrc({
      tenantId: this.configuration.tenant.id,
      queryParams: iframeQueryParams,
    });

    this.iframeSrc = this.$sce.trustAsResourceUrl(iframeSrc);

    this.onIframeEvent = ({ data = {} }) => {
      const { action, value } = data;
      switch (action) {
        case 'view':
          this.$state.go('app.connected.dashboard.contributionInformationRecapitulatif', {
            contributionInformationReference: value.contributionInformationReference,
          });
          break;
        case 'edit':
          this.$state.go('app.connected.config.depot.contributionInformation.demandeFinancement', {
            configurationId: value.teleserviceContributionInformationReference,
            demandeFinancementReference: value.demandeFinancementReference,
            contributionInformationReference: value.contributionInformationReference,
          });
          break;
      }
    };
    this.iFrameCommunicationManager.manageEvent(this.onIframeEvent);

    this.$timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#contributionsInformationList'
      );
    }, 0);
  }

  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

ContributionsInformationListComponent.$inject = [
  '$sce',
  '$httpParamSerializer',
  '$state',
  '$timeout',
  'configuration',
  'jwtSessionService',
  'IFrameCommunicationManager',
];

angular.module('portailDepotDemandeAide.dashboard').component('contributionsInformationListComponent', {
  templateUrl:
    'dashboard/contributions-information/contributions-information-list/contributions-information-list.component.html',
  controller: ContributionsInformationListComponent,
  bindings: {
    demandeFinancementReference: '<',
  },
});
