class ContributionsInformationController {
  /** @type {'demandes-financement' | 'demandes-apprenants'} */
  previousPage;
  /** @type {object} */
  previousParams;

  constructor($stateParams, $timeout, $scope, configuration, aidesService, translateService, eventsService) {
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.configuration = configuration;
    this.aidesService = aidesService;
    this.translateService = translateService;
    this.eventsService = eventsService;
  }

  $onInit() {
    this.$scope.iframeSrc = this.getIframeSrc();

    this.$scope.iframeTitle = this.translateService.translateContent(
      'connected.config.depot.contributionInformation.title'
    );

    this.$timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#contributionInformationIframe'
      );
    }, 0);
  }

  /**
   * Computes contribution information edition iframe src with config
   *
   * @returns {string}
   */
  getIframeSrc() {
    const teleserviceReference = this.$stateParams.configurationId;
    const demandeFinancementReference = this.$stateParams.demandeFinancementReference;
    const contributionReference = this.$stateParams.contributionInformationReference;

    return this.aidesService.generateIframeSrc(
      `${this.configuration.ux}${this.configuration.tenant.id}/depot/${teleserviceReference}/demandes-financement/${demandeFinancementReference}/contributions-information/${contributionReference}`
    );
  }

  /**
   * On back link click, we send event to iframe to send contribution before leaving
   *
   * @returns {false} - Always false because we need to handle back as asynchronous way
   */
  onBackClick() {
    const contributionReference = this.$stateParams.contributionInformationReference;
    const tenantId = this.configuration.tenant.id;

    this.eventsService.PORTAIL_DEPOT_DEMANDE_AIDES.emitMessage('save-contribution-information', {
      contributionReference,
      tenantId,
    });

    return false;
  }
}

ContributionsInformationController.$inject = [
  '$stateParams',
  '$timeout',
  '$scope',
  'configuration',
  'aidesService',
  'translateService',
  'eventsService',
];

angular.module('portailDepotDemandeAide.depot').component('contributionInformationComponent', {
  templateUrl: 'depot/contributions-information/contributions-information.component.html',
  controller: ContributionsInformationController,
});
