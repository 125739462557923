'use strict';
angular.module('common.directives').directive('globalErrors', [
  '$rootScope',
  'configuration',
  'alertsService',
  '$transitions',
  '$translate',
  '$timeout',
  '$window',
  function ($rootScope, configuration, alertsService, $transitions, $translate, $timeout, $window) {
    'use strict';

    return {
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/global-errors/global-errors.html',
      scope: {},
      link: function ($scope) {
        // Hide generals error on state change start
        $transitions.onStart({}, function () {
          $scope.alerts = [];
        });

        var errorProcess = function (error) {
          const accountManagementError =
            error.config.url.startsWith(configuration?.user?.accountManagement) &&
            [404, 406, 409].includes(error.status);

          const tiersError =
            error.config.url.startsWith(configuration?.tiers?.url) && [403, 404, 406, 409].includes(error.status);

          const aidesError =
            error.config.url.startsWith(configuration?.aides?.url) && [403, 404, 406].includes(error.status);

          const gestionDepotDemandeError =
            error.config.url.startsWith('/gestion-depot-demandes') && [403, 404, 406, 409].includes(error.status);

          const persistenceError =
            error.config.url.startsWith(configuration?.persistence) && [406, 502].includes(error.status);

          const documentCollectError =
            error.config.url.startsWith('/document-collect') && [409, 404].includes(error.status);

          return (
            accountManagementError ||
            tiersError ||
            aidesError ||
            gestionDepotDemandeError ||
            persistenceError ||
            documentCollectError
          );
        };

        // Check errors http
        $rootScope.$on('$httpError', function (event, error) {
          if (typeof error?.config?.url === 'string') {
            if (errorProcess(error)) {
              return;
            }
          }

          if (error && error.status === 412) {
            $scope.alerts = [
              {
                type: 'error',
                messages: [$translate.instant('error.versionObselete')],
                title: 'error.title',
              },
            ];

            // sets focus on error message
            $timeout(() => {
              $window.scroll(0, 0);
            }, 50);
            $rootScope.$broadcast('depot.conflict');
            return;
          }

          if (error && error.status === 401) {
            return;
          }

          if (error && error.status === -1) {
            return;
          }

          // Do not show global alert error for unchanged password error because it is handled locally
          if (error?.data?.code === 'ERR_PASSWORD_UNCHANGED') {
            return;
          }

          const messages = [];

          // If a custom error message is returned, we display it
          // else, we display the general http error
          if (error.data?.message) {
            messages.push(error.data.message);
          } else {
            messages.push(
              $translate.instant('error.http', {
                detail: `${error.config.method} ${error.config.url} - ${error.status} ${error.statusText}`,
              })
            );

            // Add X-Request-ID
            if (error.config.headers && error.config.headers['X-Request-ID']) {
              messages.push(
                $translate.instant('error.xRequestId', { xRequestId: error.config.headers['X-Request-ID'] })
              );
            }
          }

          $scope.alerts = [{ type: 'error', messages: messages, title: 'error.title' }];
        });
      },
    };
  },
]);
