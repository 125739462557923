'use strict';
angular.module('portailDepotDemandeAide').component('printAide', {
  templateUrl: 'print-aide/print-aide.component.html',
  bindings: {
    mdm: '<',
    settings: '<',
    contribution: '<',
    aide: '<',
    tiers: '<',
    teleservice: '<',
    publicSettingsFinancement: '<',
  },

  controller: printAide,
});

printAide.$inject = [
  '$rootScope',
  '$window',
  '$filter',
  '$location',
  'anonymizationService',
  'AidesConstant',
  '$timeout',
];
/**
 *
 * @param {object} $rootScope
 * @param {object} $window
 * @param {object} $filter
 * @param {object} $location
 * @param {object} anonymizationService
 * @param {object} AidesConstant
 * @param {object} $timeout
 */
function printAide($rootScope, $window, $filter, $location, anonymizationService, AidesConstant, $timeout) {
  this.$onInit = function () {
    $rootScope.hideFooter = true;
    this.demandeur =
      this.aide.history.events.find((event) => event.reference === 'TRANSMITTED')?.user?.title ??
      _.get(this.aide.history.begin, 'user.title');

    // Logo and labels from referentiel-financement
    this.configStandard = _.get(this.publicSettingsFinancement, 'recapitulatifStandard', {});
    // PLAID-71558: we want to anonymize IBAN when recap pdf is generated
    // if uri has anonymize in query (meaning invoked by ref-fi)
    if ($location.search().anonymize && this.aide?.domiciliationBancaire) {
      this.anonymize();
    }
    // If transmitted, display transmission date
    if (this.aide.status !== AidesConstant.demandeStatuts.REQUESTED) {
      const historyEvents = this.aide.history?.events ?? [];
      const getStatusEventDate = (status) =>
        historyEvents.find(({ type, reference }) => type === 'STATUS' && reference === status);

      const dateTransmissionEvent =
        getStatusEventDate(AidesConstant.demandeStatuts.REGISTERED) ??
        getStatusEventDate(AidesConstant.demandeStatuts.TRANSMITTED) ??
        {};

      this.dateTransmission = $filter('date')(dateTransmissionEvent.date, 'short');
      if (this.dateTransmission && !this.dateTransmission.includes(this.settings.timeZone)) {
        this.dateTransmission += ` (${this.settings.timeZone})`;
      }
    }

    // recapitulatif title
    if (this.contribution?.typeContribution === 'REDIRECTION' && this.contribution.statut === 'ASKED') {
      this.recapitulatifTitle = `${this.teleservice.libelle.value} - ${this.aide.beneficiaires[0].title}`;
    } else {
      this.recapitulatifTitle = this.aide.title;
    }

    this.displayContactSection =
      this.teleservice?.contact?.identification ||
      this.teleservice?.contact?.courriel?.value ||
      this.teleservice?.contact?.telephone?.value;
  };

  /**
   * anonymize aide.domiciliationBancaire object through anonymizationService
   */
  this.anonymize = () => {
    this.aide.domiciliationBancaire = anonymizationService.anonymizeDomiciliationBancaire(
      this.aide.domiciliationBancaire
    );
  };

  this.$onDestroy = function () {
    $rootScope.hideFooter = false;
  };

  this.print = function () {
    // Keep track of unanonymize data
    const currentDomiciliationBancaire = _.cloneDeep(this.aide.domiciliationBancaire);
    this.anonymize();
    // we need to make sure that DOM has been updated before asking for print
    $timeout(() => {
      $window.print();
    }, 100);
    // we need to restore state
    const that = this;
    $timeout(function () {
      that.aide.domiciliationBancaire = currentDomiciliationBancaire;
    }, 1000);
  };
}
