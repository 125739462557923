class ContributionsInformationRecapController {
  /** @type {string} */
  contributionInformationReference;

  constructor($timeout, $scope, configuration, aidesService, translateService) {
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.configuration = configuration;
    this.aidesService = aidesService;
    this.translateService = translateService;
  }

  $onInit() {
    this.$scope.iframeSrc = this.getIframeSrc();
    this.$scope.iframeTitle = this.translateService.translateContent(
      'connected.dashboard.contributions-information.recap'
    );

    this.$timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#contributionInformationRecapIframe'
      );
    }, 0);
  }

  /**
   * Computes contribution information edition iframe src with config
   *
   * @returns {string}
   */
  getIframeSrc() {
    return this.aidesService.generateIframeSrc(
      `${this.configuration.ux}${this.configuration.tenant.id}/contributions-information-recap-view`,
      {
        contributionReference: this.contributionInformationReference,
      }
    );
  }
}

ContributionsInformationRecapController.$inject = [
  '$timeout',
  '$scope',
  'configuration',
  'aidesService',
  'translateService',
];

angular.module('portailDepotDemandeAide.dashboard').component('contributionInformationRecapitulatifComponent', {
  templateUrl: 'dashboard/contributions-information/recapitulatif/contribution-information-recapitulatif.html',
  controller: ContributionsInformationRecapController,
  bindings: {
    contributionInformationReference: '<',
  },
});
