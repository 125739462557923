class DemandeApprenantRecapitulatifComponent {
  constructor($rootScope, aidesService, $state, $stateParams, $timeout, IFrameCommunicationManager, translateService) {
    this.$rootScope = $rootScope;
    this.aidesService = aidesService;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.iFrameCommunicationManager = new IFrameCommunicationManager('#demandeApprenantRecapitulatifIframe');
    this.translateService = translateService;
  }

  $onInit() {
    // init iFrame src
    this.demandeApprenantRecapitualtifIframeSrc = this.aidesService.generateDemandeApprenantRecapitulatifIframeSrc(
      this.demandeFinancementReference,
      { fromEntreeFormation: this.fromEntreeFormation },
      this.fromEntreeFormation ? 'entree-formation' : undefined
    );

    const kind = this.fromEntreeFormation ? 'entreesFormation' : 'demandesApprenants';

    const previousState = `app.connected.dashboard.${
      this.fromEntreeFormation ? 'entreesFormation' : 'demandesApprenants'
    }`;
    const previousParams = {
      activeTab: this.$stateParams.fromTab,
      page: this.$stateParams.fromPage,
      activeFilters: this.$stateParams.activeFilters,
    };

    this.iframeTitle = this.translateService.translateContent(`connected.dashboard.${kind}.recapitulatif.title`);

    this.$timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#demandeApprenantRecapitulatifIframe'
      );
    }, 0);

    this.iFrameCommunicationManager.manageEvent(({ data }) => {
      const { route, action, value } = data;
      switch (action) {
        case 'go-back-to-list':
          if (route === 'entree-formation-recapitulatif-view.ux') {
            this.$rootScope.$broadcast('refresh-menu');
            this.$state.go(previousState, previousParams);
          }
          break;
        case 'view':
          this.$state.go('app.connected.dashboard.contributionInformationRecapitulatif', {
            contributionInformationReference: value.contributionInformationReference,
          });
          break;
        case 'edit':
          this.$state.go('app.connected.config.depot.contributionInformation.demandeApprenant', {
            configurationId: value.teleserviceContributionInformationReference,
            demandeFinancementReference: value.demandeFinancementReference,
            contributionInformationReference: value.contributionInformationReference,
          });
          break;
      }
    });
  }

  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

DemandeApprenantRecapitulatifComponent.$inject = [
  '$rootScope',
  'aidesService',
  '$state',
  '$stateParams',
  '$timeout',
  'IFrameCommunicationManager',
  'translateService',
];

angular.module('demandes-apprenants.components').component('demandeApprenantRecapitulatifComponent', {
  templateUrl: 'dashboard/demandes-apprenants/recapitulatif/demande-apprenant-recapitulatif.html',
  controller: DemandeApprenantRecapitulatifComponent,
  bindings: {
    demandeFinancementReference: '<',
    fromEntreeFormation: '<',
  },
});
