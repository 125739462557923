'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleCriteresController', [
  '$rootScope',
  '$scope',
  '$translate',
  '$q',
  '$modal',
  'aidesService',
  'criteresService',
  function ($rootScope, $scope, $translate, $q, $modal, aidesService, criteresService) {
    'use strict';

    // fetch the demande teleservice version if in edition
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageCritereEligibilite', {});
    $scope.criteresStatus = $scope.criteresStatus || {
      criteres: false,
      attestation: false,
      attempted: false,
    };

    if ($scope.pageOptions.actif) {
      let criteresToDisplay = $scope.pageOptions.CritereEligibilite;
      if ($scope.contribution?.typeContribution === 'MODIFICATION')
        criteresToDisplay = criteresService.excludeCodeCampagne(criteresToDisplay);

      // override existing criteres configuration from TS
      $scope.criteresEligibilite = criteresToDisplay.map((critere) => {
        const foundCritere = $scope.aide.criteresEligibilite.find(
          (critereAide) => critere.reference === critereAide.reference
        );
        if (foundCritere?.reponseSaisie !== undefined) {
          critere.reponseSaisie = foundCritere.reponseSaisie;
        }
        return critere;
      });
    }

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = true;
    $scope.navigate.ns = $scope.criteresConfiguration.ns;
    $scope.criteresConfiguration.remoteValidation = false;

    /**
     * Try to go to the next page
     *
     * @param {boolean} valid
     * @returns {void}
     */
    function tryNext(valid) {
      // Set the entity criteres result with the UX computed value
      $scope.aide.eligible = $scope.criteresStatus.criteres && valid;
      if ($scope.aide.eligible) {
        $scope.confirmNext(false);
      } else {
        $modal({
          scope: $scope,
          template: 'depot/simple/criteres/depot-simple-criteres-nok.html',
        });
      }
    }

    $scope.navigate.next = function () {
      // Set the attempted marker to true
      $scope.criteresStatus.attempted = true;
      if (
        !$scope.criteresStatus.formValid ||
        ($scope.pageOptions.attestationHonneur && !$scope.criteresStatus.attestation)
      ) {
        // Not all criteres were captured by user. The directive will show the errors, jut don't navigate yet.
        return;
      }

      // we assume external API criteres are of type STRING
      const externalApiCriteres = _.filter($scope.criteresEligibilite, ['type', 'STRING']);
      const promises = _.map(externalApiCriteres, (critere) => {
        return aidesService.checkExternalCritere(critere, $scope.teleserviceConfiguration.reference);
      });
      // re-init the custom messages list
      $scope.criteresStatus.messages = [];

      $q.all(promises)
        .then((results) => {
          // filter invalid results : eligibility of a service differs from the awaited response
          const invalidCriteres = results.filter(
            (result) => result.result?.eligible !== result.critere?.reponseAttendue
          );
          $scope.criteresStatus.messages = invalidCriteres.map(({ critere }) => critere.libelleKO);
          return invalidCriteres.length === 0;
        })
        .then((valid) => tryNext(valid))
        .catch((rejection) => {
          if (rejection.status === 403) {
            // too much API calls
            const message = $translate.instant('teleservice.criteres.api.overflow');
            $scope.alerts = [{ type: 'error', messages: [message], title: 'error.title' }];
          } else {
            $rootScope.$broadcast('$httpError', rejection);
            tryNext(false);
          }
        });
    };

    $scope.confirmNext = function (forget) {
      // We reassign criteres to aide before goToStep (which is saving aide)
      $scope.aide.criteresEligibilite = $scope.criteresEligibilite;

      // If the user is associated to a tiers go to the recap of this tiers
      // else start creating a new tiers
      if ($scope.tiers) {
        $scope.goToStep('demandeur-recapitulatif', forget);
      } else {
        $scope.goToStep('demandeur-famille', forget);
      }
    };

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'criteresEligibilite';

    if (!$scope.pageOptions.actif || $scope.pageOptions.actif === 'false') {
      $scope.confirmNext(true);
    }

    $scope.isCriteresLocked =
      $scope.teleserviceConfiguration?.urlApiExterneInitialisation &&
      $scope.pageOptions.CritereEligibilite?.some((critere) => critere.type === 'STRING') &&
      $scope.aide?.history?.begin?.metadata?.stepsVisited?.includes('criteres');
  },
]);
