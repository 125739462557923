'use strict';
angular.module('common.directives').directive('pageTitle', [
  function () {
    'use strict';

    return {
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/page-title/page-title.html',
      scope: {
        pageConfigurationKey: '=',
        state: '=',
      },
      link: function ($scope) {
        // display the title if the current page name is not included in the following array
        $scope.showTitle = function () {
          const hideIfPage = [
            'app.connected.dashboard.accueil',
            'app.connected.tiers-selection',
            'app.connected.dashboard.aides.demandesAides',
            'app.connected.dashboard.aides.demandesAides.aides',
            'app.connected.dashboard.aides.demandesAides.sharedAides',
            'app.connected.dashboard.demandesApprenants',
            'app.connected.dashboard.entreesFormation',
            'app.connected.dashboard.demandeApprenantRecapitulatif',
            'app.connected.dashboard.entreeFormationRecapitulatif',
            'app.connected.config.depot.contributionInformation.demandeFinancement',
            'app.connected.config.depot.contributionInformation.demandeApprenant',
            'app.connected.dashboard.contributionInformationRecapitulatif',
          ];
          return !hideIfPage.includes($scope.state.name);
        };
      },
    };
  },
]);
