'use strict';
/*eslint complexity:0 */
/*eslint max-statements:0 */ TiersModel.$inject = [
  'Individu',
  'Pcs',
  'Siret',
  'Coordonnees',
  'Geographique',
  'Courriel',
  'Piece',
  'User',
  'LinkedUser',
  'Conformite',
  'Link',
  'MasterDataRecord',
  'LocalizedText',
  'mdmUtils',
  'configuration',
  'Telephone',
  'Representant',
  'validationService',
];
angular.module('tiers.model').factory('TiersModel', TiersModel);

TiersModel.$injector = [
  'Individu',
  'Pcs',
  'Siret',
  'Coordonnees',
  'Geographique',
  'Courriel',
  'Piece',
  'User',
  'LinkedUser',
  'Conformite',
  'Link',
  'MasterDataRecord',
  'LocalizedText',
  'mdmUtils',
  'configuration',
  'Telephone',
  'Representant',
  'validationService',
];

/**
 *
 * @param {object} Individu
 * @param {object} Pcs
 * @param {object} Siret
 * @param {object} Coordonnees
 * @param {object} Geographique
 * @param {object} Courriel
 * @param {object} Piece
 * @param {object} User
 * @param {object} LinkedUser
 * @param {object} Conformite
 * @param {object} Link
 * @param {object} MasterDataRecord
 * @param {object} LocalizedText
 * @param {object} mdmUtils
 * @param {object} configuration
 * @param {object} Telephone
 * @param {object} Representant
 * @param {object} validationService
 * @returns {object}
 */
function TiersModel(
  Individu,
  Pcs,
  Siret,
  Coordonnees,
  Geographique,
  Courriel,
  Piece,
  User,
  LinkedUser,
  Conformite,
  Link,
  MasterDataRecord,
  LocalizedText,
  mdmUtils,
  configuration,
  Telephone,
  Representant,
  validationService
) {
  /**
   * Classe Tiers. Objet canonique Tiers
   *
   * @param {object} data Données d'un utilisateur en persistance utilisé pour initialiser l'objet
   * @param {object} mdm
   * @class Tiers
   */
  function Tiers(data, mdm) {
    data = data || {};
    mdm = mdm || {};

    // On conserve le caractère active/inactif d'un tiers, si il vient
    // du back et que le paramètre existe dans l'aide
    this.active = data.active || true;

    // Version de l'entité Tiers
    this.version = data.version;

    this.id = data.id;
    this.raisonSociale = data.raisonSociale;
    this.libelleComplementaire = new LocalizedText(data.libelleComplementaire);
    this.sigle = data.sigle;
    if (_.get(data, 'civilite.href')) {
      this.civilite = new Link({
        title: data.civilite && data.civilite.title,
        href: data.civilite && data.civilite.href,
        expand: (data.civilite && data.civilite.expand) || new MasterDataRecord(),
      });
    }
    // Enseigne
    this.enseigne = data.enseigne;
    this.individu = new Individu(data.individu);
    this.NIR = data.NIR;
    this.PCS = data.PCS;
    this.famille = data.famille;
    this.formeJuridique = data.formeJuridique;
    this.logo = data.logo;
    this.SIRET = new Siret(data.SIRET);
    this.sansSIRET = data.sansSIRET;
    this.NAFNiv732 = mdmUtils.refOrEntry(mdm.naf, data.NAFNiv732);
    this.NAF = data.NAF || angular.copy(this.NAFNiv732) || {};
    // IF NAFNiv732 isn't defined, try to set it from NAF url
    if (!this.NAFNiv732 && this.NAF.href) {
      var nafRefMatch = this.NAF.href.match(/\('(.+)'\)$/);
      this.NAFNiv732 = nafRefMatch ? mdmUtils.refOrEntry(mdm.naf, nafRefMatch.pop()) : {};
    }
    this.CJ = data.CJ;
    this.thematiques = data.thematiques;
    this.status = data.status;
    this.reference = data.reference;
    this.referenceAdministrative = data.referenceAdministrative;

    // Informations complémentaires - Date d'immatriculation
    if (data.dateImmatriculation) {
      this.dateImmatriculation = data.dateImmatriculation;
      this.dateImmatriculationDisplay = moment(data.dateImmatriculation).format('L');
    }

    this.capitalSocial = data.capitalSocial;
    this.activitePrincipale = data.activitePrincipale;
    this.appartenanceGroupe = data.appartenanceGroupe;
    this.raisonSocialeGroupe = data.raisonSocialeGroupe;
    this.comptesConsolides = data.comptesConsolides;

    // Annotations
    this.annotations = data.annotations || [];

    // Corrélations
    this.correlations = data.correlations || [];

    // Classifications
    this.classifications = data.classifications || [];

    // Adresses du tiers
    this.situations = _.map(data.situations || [], function (coordonnees) {
      coordonnees = _.merge(coordonnees, coordonnees.expand);
      _.unset(coordonnees, 'expand');
      return new Coordonnees(coordonnees, mdm);
    });

    // Représentants
    this.representants = _.map(data.representants || [], function (representant) {
      representant = _.merge(representant, representant.expand);
      _.unset(representant, 'expand');
      return new Representant(representant, mdm);
    });

    // Pièces
    this.pieces = _.map(data.pieces || [], function (piece) {
      return new Piece(piece);
    });

    // Utilisateurs du tiers
    this.linkedUsers = _.map(data.linkedUsers || [], function (linkedUser) {
      return new LinkedUser(linkedUser);
    });

    // User
    this.user = new Link({
      title: data.user && data.user.title,
      href: data.user && data.user.href,
      expand: new User(data.user && data.user.expand),
    });

    // Informations complémentaires du tiers
    this.complements = data.complements;
    // Exercice et données financières
    this.effectifs = data.effectifs;
    this.donneesFinancieres = data.donneesFinancieres;
    this.effectifsGroupe = data.effectifsGroupe;
    this.donneesFinancieresGroupe = data.donneesFinancieresGroupe;

    // Domiciliations Bancaires
    this.domiciliationsBancaires = data.domiciliationsBancaires || [];
    _.each(this.domiciliationsBancaires, function (domiciliation) {
      _.set(
        domiciliation,
        'pieces',
        _.map(_.get(domiciliation, 'pieces'), function (piece) {
          return new Piece(piece);
        })
      );
    });

    // conformite
    this.conformite = new Conformite(data.conformite);

    // Evénéments du tiers - Stockage de la date de dernière modification du tiers
    this.history = data.history || {
      begin: {},
      events: [],
      end: {},
    };

    this.externalData = data.externalData;

    // Complète le tiers avec d'autres données éventuelles.
    // De préférence penser à ajouter la clé au dessus, mais grâce à ce mécanisme
    // on évite de la perte de donnée
    for (var i in data) {
      if (this[i] === undefined) {
        this[i] = data[i];
      }
    }

    this.courriels = data.courriels || _.get(this.situations, '[0].courriels_') || [];
    this.telephones = data.telephones || _.get(this.situations, '[0].telephones') || [];
    this.sitesWeb = data.sitesWeb || [];

    // thematiquesLiees
    this.thematiquesLiees = {};
    _.forEach(data.thematiquesLiees, (thematique, name) => {
      this.thematiquesLiees[_.camelCase(name)] = _.get(thematique, 'expand', thematique);
    });
  }

  var cleanTelephones = function (telephones) {
    if (_.isArray(telephones)) {
      _.remove(telephones, function (tel) {
        return !tel.value;
      });
      return telephones;
    }
  };

  /**
   * Modification de l'origine de l'adresse (France ou étranger)
   *
   * @param  {boolean} etrangere True si étrangère, false sinon
   */
  Tiers.prototype.changeOriginAddress = function (etrangere) {
    this.situations[0].checkAdresseEtrangere(etrangere);
    this.SIRET.NIC = undefined;
    this.SIRET.SIREN = undefined;
  };

  /**
   * Défini une adresse email au niveau du tiers à partir de l'utilisateur connecté
   *
   * @param  {object} user User
   */
  Tiers.prototype.fillEmail = function (user) {
    if (user && user.emails && user.emails.length > 0) {
      // Email
      if (this.courriels.length === 0) {
        this.courriels.push(new Courriel());
      }
      this.courriels[0].value = user.emails[0];
      this.courriels[0].TYPE = 'internet';
    }
  };

  /**
   * Fills individu's information from connected user's information
   *
   * @param  {object} user Utilisateur connecté
   * @param  {mdm} mdm  Mdm
   * @param  {boolean} onlyEmpty  only replace empty values
   * @returns {void}
   */

  Tiers.prototype.fillInformationsTiersWithUser = function (user, mdm, onlyEmpty = false) {
    const tiersPhysique = this?.famille?.expand?.personnaliteJuridique === 'PHYSIQUE';
    const tiersParticulier = this?.famille?.expand?.typeFamille === 'TIERS_PARTICULIER';
    const hasIdentityInformationsFilled =
      this.raisonSociale || this.individu?.Prenoms?.PrenomUsuel || this.individu?.Noms?.NomUsage || this.civilite?.href;
    const shouldFillTiersDataWithUserInformations =
      tiersParticulier || (tiersPhysique && !hasIdentityInformationsFilled);

    if (shouldFillTiersDataWithUserInformations && user) {
      const cleanIndividu = (value) => value.replace(/_/g, '-').replace(/\./g, '');

      const replaceCivilite = _.find(mdm.civilites.array, ['expand.libelle.value', user.name.honorificPrefix]);
      const replaceNomUsage = cleanIndividu(user.name.familyName);
      const replacePrenomUsuel = cleanIndividu(user.name.givenName);

      this.civilite = this.civilite && onlyEmpty ? this.civilite : replaceCivilite;
      this.individu.Noms.NomUsage =
        this.individu?.Noms?.NomUsage && onlyEmpty ? this.individu.Noms.NomUsage : replaceNomUsage;
      this.individu.Prenoms.PrenomUsuel =
        this.individu?.Prenoms?.PrenomUsuel && onlyEmpty ? this.individu.Prenoms.PrenomUsuel : replacePrenomUsuel;
    }
  };

  /**
   * Recursively unset the properties that have an empty value from the object
   *
   * /!\ Mutates the object
   *
   * @param {object} obj object
   * @returns {void}
   */
  function unsetPropertiesWithEmptyValues(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }

    for (const property of Object.keys(obj)) {
      const value = obj[property];

      if (value === '' || value === null) {
        delete obj[property];
      } else if (typeof value === 'object') {
        unsetPropertiesWithEmptyValues(value);
      }
    }
  }

  /**
   * Méthode qui permet de nettoyer l'objet tiers avant de l'envoyer au back-office
   *
   * @returns {object}
   */
  Tiers.prototype.getCleanEntity = function () {
    var tiers = angular.copy(this);

    delete tiers.specificData;
    delete tiers.dateImmatriculationDisplay;
    delete tiers.visa;

    // NAFNiv732
    if (_.get(tiers, 'NAFNiv732.expand.reference')) {
      tiers.NAF = angular.copy(tiers.NAFNiv732);
      delete tiers.NAF.expand;
      tiers.NAFNiv732 = _.get(tiers, 'NAFNiv732.expand.reference');
    }

    if (_.isNull(tiers.NAFNiv732)) {
      delete tiers.NAFNiv732;
      delete tiers.NAF;
    }

    if (tiers.civilite) {
      delete tiers.civilite.expand;
      delete tiers.civilite.error;
    }

    if (tiers.individu) {
      tiers.individu = tiers.individu.getCleanEntity();
    }

    // SIRET
    if (tiers.SIRET && !tiers.SIRET.SIREN && !tiers.SIRET.NIC) {
      delete tiers.SIRET;
    }

    // Moral / Physique
    if (_.get(tiers, 'famille.expand.personnaliteJuridique') === 'MORALE') {
      delete tiers.individu;
      delete tiers.civilite;
    } else if (_.get(tiers, 'famille.expand.personnaliteJuridique') === 'PHYSIQUE') {
      delete tiers.representants;
    }

    // If the tiers doesn't have status, we're creating an aide
    // so, we must keep ux input controls
    // https://jira.mgdis.fr/browse/PLAID-1161
    var keepUxControls = tiers.status ? false : true;

    // Situations
    tiers.situations = _.map(tiers.situations, function (situation) {
      return situation.getCleanEntity();
    });

    // Représentants
    tiers.representants = _.map(tiers.representants, function (representant) {
      return representant.getCleanEntity(keepUxControls);
    });

    // Linked users
    tiers.linkedUsers = _.map(tiers.linkedUsers, function (linkedUser) {
      delete linkedUser.expand;
      delete linkedUser.error;
      return linkedUser;
    });

    // Users
    if (tiers.user?.getCleanEntity) {
      tiers.user = tiers.user.getCleanEntity();
    }

    // Pièces
    tiers.pieces = _.map(tiers.pieces, function (piece) {
      return piece.getCleanEntity();
    });

    // Domiciliations bancaires
    _.each(tiers.domiciliationsBancaires, function (domiciliationLink) {
      _.set(
        domiciliationLink,
        'pieces',
        _.map(_.get(domiciliationLink, 'pieces', domiciliationLink.pieces), function (piece) {
          return piece.getCleanEntity(true);
        })
      );
    });

    // Pays
    if (tiers.coordonnees && tiers.coordonnees.geographique && tiers.coordonnees.geographique.Pays) {
      tiers.coordonnees.geographique.Pays = {
        code: tiers.coordonnees.geographique.Pays.reference,
        value: tiers.coordonnees.geographique.Pays.libelle,
      };
    }

    // Famille expand
    _.unset(tiers, 'famille.expand');
    tiers.famille = _.pick(tiers.famille, ['href', 'title']);

    // Forme juridique expand
    if (tiers.formeJuridique && (tiers.formeJuridique.expand || tiers.formeJuridique.error)) {
      delete tiers.formeJuridique.expand;
      delete tiers.formeJuridique.error;
    }

    if (tiers.telephones && tiers.telephones.length > 0) {
      tiers.telephones = cleanTelephones(tiers.telephones);
    }

    if (tiers.sitesWeb && _.isArray(tiers.sitesWeb) && tiers.sitesWeb.length > 0) {
      tiers.sitesWeb = tiers.sitesWeb.filter((siteWeb) => validationService.verifyUrl(siteWeb));
    }

    if (Array.isArray(tiers.courriels)) {
      tiers.courriels = tiers.courriels.filter((courriel) => validationService.verifyEmail(courriel?.value));
    }

    if (tiers.thematiquesLiees) {
      unsetPropertiesWithEmptyValues(tiers.thematiquesLiees);
    }

    return tiers;
  };

  /**
   * @param {object} data
   * @param {object} mdm
   */
  function TiersModels(data, mdm) {
    data = data || {};
    mdm = mdm || {};

    this.tiers = new Tiers(data, mdm);
  }

  return TiersModels;
}
