class BackLinkPageController {
  /** @type {Function} */
  onBackClick;

  constructor($rootScope, $scope, routeUtilsService, translateService, eventsService) {
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.routeUtilsService = routeUtilsService;
    this.translateService = translateService;
    this.eventsService = eventsService;

    this.goBackHandler = this.goBack.bind(this);
  }

  $onInit() {
    const hasPreviousState = this.$rootScope.previousStates.length > 0;
    const defaultState = this.routeUtilsService.getDefaultPreviousState();
    const previousState = hasPreviousState ? this.$rootScope.previousStates.at(-1).state.name : defaultState;

    this.$scope.linkText = this.translateService.translateContent(`back-link.${previousState}`);

    this.eventsService.PORTAIL_DEPOT_DEMANDE_AIDES.addEventListener('go-back', this.goBackHandler);
  }

  $onDestroy() {
    this.eventsService.PORTAIL_DEPOT_DEMANDE_AIDES.removeEventListener('go-back', this.goBackHandler);
  }

  /**
   * Proxy link click to parent
   */
  onBackLinkClick() {
    const shouldGoBack = this.onBackClick?.();
    if (!this.onBackClick || shouldGoBack) this.goBack();
  }

  /**
   * Go back to previous page
   */
  goBack() {
    this.routeUtilsService.goToPreviousRouterState();
  }
}

BackLinkPageController.$inject = ['$rootScope', '$scope', 'routeUtilsService', 'translateService', 'eventsService'];

angular.module('common.components').component('backLinkPage', {
  templateUrl: 'common/common-components/back-link-page/back-link-page.component.html',
  transclude: true,
  controller: BackLinkPageController,
  bindings: {
    onBackClick: '&?',
  },
});
