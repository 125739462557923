'use strict';
angular.module('aides.model').factory('Aide', [
  'Tiers',
  'Conformite',
  'Link',
  'LinkedUser',
  'Piece',
  'User',
  'Complement',
  'LocalizedText',
  'LinkUser',
  function (Tiers, Conformite, Link, LinkedUser, Piece, User, Complement, LocalizedText, LinkUser) {
    'use strict';

    /**
     * Classe Aides. Objet canonique Aide
     *
     * @param {object} data Données d'un utilisateur en persistance utilisé pour initialiser l'objet
     * @param {object} mdm Mdm
     * @class Aide
     * @classdesc Aide canonique
     */
    function Aide(data, mdm) {
      /*eslint complexity:0 */
      /*eslint max-statements:0 */

      data = data || {};

      // On conserve le caractère active/inactif d'une aide, si elle vient
      // du back et que le paramètre existe dans l'aide
      this.active = data.active || true;

      // Version de l'entité Aides
      this.version = data.version;

      this.id = data.id;
      this.origin = data.origin;

      // add bourse
      this.bourse = data.bourse;

      // add campagne
      this.campagne = data.campagne;

      // add demandesReport
      this.demandesReport = data.demandesReport;

      // Cloture is false by default
      this.cloture = data.cloture || {
        value: false,
      };

      // User
      this.user = new LinkedUser(data.user);
      delete this.user.form;

      // linked users
      if (data.linkedUsers && data.linkedUsers.length) {
        this.linkedUsers = (data.linkedUsers || []).map((linkedUser) => new LinkedUser(linkedUser));
      }

      this.date = data.date;
      this.dateDemande = data.dateDemande;
      this.status = data.status;
      this.statut = data.statut;
      this.reference = data.reference;
      this.referenceAdministrative = data.referenceAdministrative;
      this.demandeEnNature = data.demandeEnNature;
      this.tenant = data.tenant;
      this.controlesEligibilite = data.controlesEligibilite;
      // Financeur principal
      this.financeurPrincipal = data.financeurPrincipal;

      // Teleservice extension
      this.teleserviceExtension = data.teleserviceExtension;

      // Evénéments de l'aide - Stockage de la date de dernière modification de l'aide
      this.history = data.history || {
        begin: {},
        events: [],
        end: {},
      };

      this.classifications = data.classifications;
      this.correlations = data.correlations || [];
      this.annotations = data.annotations || [];
      this.dossiersFinancement = data.dossiersFinancement || [];

      // Conservation de la clé "specifiques" au niveau du modèle
      // afin qu'il ne soit pas supprimé lors de la modification de l'aide
      this.specifiques = data.specifiques || [];

      this.objet = new LocalizedText(data.objet);
      this.description = new LocalizedText(data.description);
      this.montant = data.montant;
      this.porteur = data.porteur;
      this.libelle = data.libelle;

      // caracteristiques sociales
      this.caracteristiquesSociales = data.caracteristiquesSociales;

      // Le demandeur et les bénéficiaire peuvent être des Tiers complet ou leurs identifiant
      this.demandeur = new Link(data.demandeur);

      // Add expand only if present, or initialize it if no href
      if (_.get(data, 'demandeur.expand')) {
        this.demandeur.expand = new Tiers(data.demandeur && data.demandeur.expand, mdm);
      } else {
        delete this.demandeur.expand;
      }

      this.beneficiaires = (data.beneficiaires || []).map((beneficiaire) => {
        const beneficiaireLink = new Link(beneficiaire);

        // Add expand only if present or initialize it if no href
        if (beneficiaire.expand || !beneficiaire.href) {
          beneficiaireLink.expand = new Tiers(beneficiaire.expand, mdm);
        } else {
          delete beneficiaireLink.expand;
        }
        return beneficiaireLink;
      });

      this.mandataires = (data.mandataires || []).map((mandataire) => {
        return new Link({
          title: mandataire.title,
          href: mandataire.href,
          expand: new Tiers(mandataire.expand, mdm),
        });
      });

      this.exerciceBudgetaire = data.exerciceBudgetaire;
      this.conformite = new Conformite(data.conformite);
      this.recevabilite = data.recevabilite;
      this.demandesComplementPieces = (data.demandesComplementPieces || []).map((complementLink) => {
        return new Link({
          title: complementLink.title,
          href: complementLink.href,
          expand: new Complement(complementLink.expand),
        });
      });

      this.dispositif = data.dispositif || undefined;
      if (this.dispositif?.expand === null) {
        delete this.dispositif.expand;
      }

      this.teleservice = data.teleservice || new Link();

      // Pieces
      this.pieces = (data.pieces || []).map((piece) => new Piece(piece));

      // Plan financement
      this.planFinancement = (data.planFinancement || []).map((planFin) => {
        if (!planFin.periode) planFin.periode = {};
        return planFin;
      });

      // handle multi financeur
      if (!_.isNil(data.multiFinanceur)) {
        this.multiFinanceur = data.multiFinanceur;
        this.multiFinancement = data.multiFinancement;
      }
      if (!_.isNil(data.optionsMultiFinanceur)) {
        this.optionsMultiFinanceur = data.optionsMultiFinanceur;
      }

      // Financements antérieurs
      this.financementAnterieur = data.financementAnterieur || [];

      // Criteres d'éligibilité
      this.criteresEligibilite = data.criteresEligibilite || [];
      this.eligible = data.eligible;

      this.motif = data.motif;
      this.linkedUsers = data.linkedUsers || [];

      // Hypermedia - Links
      this._links = data._links || {};

      // Gestionnaire
      this.gestionnaire = data.gestionnaire;

      // title
      this.title = data.title;

      // etablissement
      this.etablissement = data.etablissement;

      // domiciliation bancaire
      this.domiciliationBancaire = data.domiciliationBancaire;

      //Thématique
      this.thematique = data.thematique;

      //Sous-thématique
      this.sousThematique = data.sousThematique;

      //Fréquence
      this.frequence = data.frequence;

      //Nature
      this.nature = data.nature;

      //Période
      this.periode = data.periode;

      //Durée
      this.duree = data.duree;

      // Avis préalable
      this.avisPrealables = data.avisPrealables || [];

      // Dans le cas d'une demande multi-financeurs synchrone:
      if (_.has(data, 'preAvisPriseEnCharge')) this.preAvisPriseEnCharge = data.preAvisPriseEnCharge;

      //Contrat
      if (data.contrat && data.contrat.href) this.contrat = data.contrat;

      //Thématique
      this.intituleProjet = data.intituleProjet || '';

      //Views
      this.views = data.views;

      // Localisation
      this.localisations = data.localisations;

      // Contributions pour modification
      this.contributionsPourModification = data.contributionsPourModification;

      this.contributionsRedirection = data.contributionsRedirection;

      this.demandeurEtBeneficiaire = data.demandeurEtBeneficiaire;

      // Signataire
      if (data.signataire) {
        this.signataire = new LinkUser({
          title: _.get(data, 'signataire.title'),
          href: _.get(data, 'signataire.href'),
          email: _.get(data, 'signataire.email'),
        });
      }

      this.evaluation = data.evaluation;
      if (data.dateRealisation) {
        this.dateRealisation = data.dateRealisation;
      }
      if (data.periodeRealisation) {
        this.periodeRealisation = data.periodeRealisation;
      }

      // Moyens Humains
      this.moyensHumains = data.moyensHumains || {};

      //groupeGestion
      if (data.groupeGestion) {
        this.groupeGestion = data.groupeGestion;
      }

      // Comite pour recevabilite
      if (data.comiteRecevabilite) {
        this.comiteRecevabilite = data.comiteRecevabilite;
      }

      this._metadata = data._metadata;
      this.idCpo = data.idCpo;

      // Indicateurs & hierarchies
      this.indicateursSaisis = data.indicateursSaisis || [];
      this.hierarchies = data.hierarchies || [];

      this.propagation = data.propagation;

      this.externalData = data.externalData;

      this.justification = data.justification;
    }

    /**
     * Méthode qui permet de nettoyer l'objet aide avant de l'envoyer au back-office
     *
     * @returns {object}
     */
    Aide.prototype.getCleanEntity = function () {
      const aide = angular.copy(this);

      if (aide.teleservice) delete aide.teleservice.expand;

      // Demandeur
      if (!_.isEmpty(aide.demandeur.expand)) {
        // FIX: après un rattachement de compte à une structure existante, il n'y a pas d'envoi de mail
        // car l'href du demandeur est manquant.
        aide.demandeur.href = aide.demandeur.href || _.get(aide, 'demandeur.expand.id');
        aide.demandeur.title = aide.demandeur.title || _.get(aide, 'demandeur.expand.title');
        // Do not save expand if there is an href
        if (!aide.demandeur.href) {
          aide.demandeur.expand = aide.demandeur.expand.getCleanEntity();
        } else {
          delete aide.demandeur.expand;
        }
      }

      // Beneficiaires
      (aide.beneficiaires || []).forEach((beneficiaire) => {
        if (!_.isEmpty(beneficiaire.expand)) {
          // FIX: après un rattachement de compte à une structure existante, il n'y a pas d'envoi de mail
          // car l'href du bénéficiaire est manquant.
          beneficiaire.href = beneficiaire.href || _.get(beneficiaire, 'expand.id');
          beneficiaire.title = beneficiaire.title || _.get(beneficiaire, 'expand.title');
          // Do not save expand if there is an href
          if (!beneficiaire.href) {
            beneficiaire.expand = beneficiaire.expand.getCleanEntity();
          } else {
            delete beneficiaire.expand;
          }
        }
      });

      // PlanFinancement, clean null montants
      _.each(JSONPath(`$..lignes[?(@.montant)]`, aide.planFinancement), (ligne) => {
        if (ligne.montant.ht === null) {
          delete ligne.montant.ht;
        }
        if (ligne.montant.ttc === null) {
          delete ligne.montant.ttc;
        }
        if (_.isEmpty(ligne.montant)) {
          delete ligne.montant;
        }
      });

      // Pièces
      aide.pieces = (aide.pieces || []).map((piece) => piece.getCleanEntity());

      // Clean all pieces in planFinancement lines
      const lignesWithPieces = JSONPath('$..depense.postes[*].lignes[?(@.pieces)]', aide.planFinancement);
      lignesWithPieces.forEach((ligne) => {
        ligne.pieces = ligne.pieces.map((piece) => {
          // create an instance of piece to access getCleanEntity function to clean expands in pieces.documents
          const pieceObj = new Piece(piece);
          return pieceObj.getCleanEntity();
        });
      });

      // Demandes complément
      aide.demandesComplementPieces = (aide.demandesComplementPieces || []).map((complementLink) => {
        return {
          title: complementLink.title,
          href: complementLink.href,
        };
      });

      // --- Specifiques ---
      aide.specifiques = aide.specifiques?.filter((specifique) => {
        // Empty, undefined or null object forbidden
        return specifique && !_.isUndefined(specifique.value) && !_.isNull(specifique.value);
      });

      // --- Views ---
      // Clean forms-common values
      aide.views
        ?.filter((view) => view.schema.href.includes('/forms-common/'))
        .forEach((formsCommon) => {
          if (formsCommon.hasOwnProperty('values')) delete formsCommon.values;
        });

      // On n'utilise que les champs autorisés par le contrat dans referentiel financement
      aide.specifiques = (aide.specifiques || []).map((specifique) => {
        const properties = [
          'value',
          'title',
          'hidden',
          'required',
          'schema',
          'reference',
          'dataType',
          'unit',
          'localizable',
          'subType',
        ];

        let specifiqueEntity = _.pickBy(specifique, function (prop) {
          return prop !== null && prop !== undefined;
        });
        specifiqueEntity = _.pick(specifiqueEntity, properties);

        if (specifiqueEntity.unit && typeof specifiqueEntity.unit !== 'object') {
          delete specifiqueEntity.unit;
        }
        return specifiqueEntity;
      });
      // Financement antérieur
      (aide.financementAnterieur || []).forEach((financementAnterieur) => {
        delete financementAnterieur._events;
        delete financementAnterieur.uid;
      });

      // Delete additional properties
      delete aide.cguAcceptation;

      //User
      delete aide.user.expand;

      if (aide.signataire) {
        aide.signataire = aide.signataire.getCleanEntity();
      }

      (aide.planFinancement || []).forEach((planFin) => {
        const exercice = _.get(planFin, 'periode.exercice');
        if (exercice === '' || exercice === 0) {
          _.unset(planFin, 'periode.exercice');
        }
        if (_.isNull(_.get(planFin, 'periode.fin'))) {
          _.unset(planFin, 'periode.fin');
        }
        if (_.isNull(_.get(planFin, 'periode.debut'))) {
          _.unset(planFin, 'periode.debut');
        }
      });

      if (!aide.contrat || !aide.contrat.href) delete aide.contrat;

      // Thematique & sousThematique
      if (_.isNil(aide.sousThematique)) {
        delete aide.sousThematique;
      }
      if (_.isNil(aide.thematique)) {
        // delete both
        delete aide.thematique;
        delete aide.sousThematique;
      }

      // Table of moyens humains
      if (aide.moyensHumains?.content) {
        aide.moyensHumains.content = aide.moyensHumains.content.map((elt) => {
          // there is a control only when both fields are filled
          if (typeof elt.nbPersonnes === 'number' && typeof elt.nbETPT === 'number') {
            if (elt.nbPersonnes < elt.nbETPT) {
              return { ...elt, nbPersonnes: undefined, nbETPT: undefined };
            }
          }
          return elt;
        });
      }

      return aide;
    };

    return Aide;
  },
]);
